import { __read } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Tabs from '../../../Tabs/Tabs';
import Warnings from '../../../Warnings';
import { Link } from '../../../index';
import { useConfig } from '../../../context';
import OrderCard from '../../../OrderCard';
import WxPagination from '../../../WxPagination';
import { usePagination } from '../../../WxPagination/utils';
import { useTheme } from '../../../theme';
import ProfileControls from '../../../ProfileControls';
import { OrderStatus, SortOrder, SortAttribute, useGetOrdersListPaginatedQuery, OrderFlownStatus } from '@websky/graphql';
import SimpleLoader from '../../../SimpleLoader';
import { TABLET_MIN_WIDTH } from '../../../utils';
var ORDERS_PER_PAGE = 4;
var Orders = memo(function () {
    var _a, _b, _c, _d, _e;
    var css = useTheme('AccountModule').Account;
    var t = useTranslation('Account').t;
    var _f = useConfig(), engineURL = _f.Account.engineURL, companyInfo = _f.global.companyInfo;
    var isTablet = useMediaQuery({ maxWidth: TABLET_MIN_WIDTH });
    var tabs = [
        {
            header: t('Next fligths'),
            value: 0,
            orderFlownStatus: OrderFlownStatus.Active
        },
        {
            header: t('Archive'),
            value: 1,
            orderFlownStatus: OrderFlownStatus.Flown
        }
    ];
    var _g = __read(useState(ORDERS_PER_PAGE), 2), totalOrdersCount = _g[0], setTotalOrdersCount = _g[1];
    var _h = __read(React.useState(tabs[0]), 2), activeTab = _h[0], setActiveTab = _h[1];
    var _j = usePagination(totalOrdersCount, ORDERS_PER_PAGE), pagesCount = _j.pagesCount, paginationAvailable = _j.paginationAvailable, handleChangePage = _j.handleChangePage, page = _j.page;
    var _k = useGetOrdersListPaginatedQuery({
        variables: {
            pagination: {
                pageSize: ORDERS_PER_PAGE,
                pageNum: page
            },
            selectionParams: {
                sortParameters: {
                    sortOrder: SortOrder.DESC,
                    byAttribute: SortAttribute.Id
                },
                filterParameters: {
                    bookingStatus: [OrderStatus.Booked, OrderStatus.Confirmed, OrderStatus.Cancelled],
                    orderFlownStatus: activeTab.orderFlownStatus
                }
            }
        },
        fetchPolicy: 'no-cache'
    }), ordersData = _k.data, ordersLoading = _k.loading, refetchOrders = _k.refetch;
    var onTabChange = function (value) {
        handleChangePage(1);
        setActiveTab(function () { return tabs.find(function (tab) { return tab.value === value; }); });
    };
    useEffect(function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.totalPages) {
            var totalPages = (_d = (_c = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _c === void 0 ? void 0 : _c.pageInfo) === null || _d === void 0 ? void 0 : _d.totalPages;
            var totalOrdersCount_1 = totalPages ? totalPages * ORDERS_PER_PAGE : null;
            setTotalOrdersCount(totalOrdersCount_1);
        }
    }, [(_b = (_a = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _a === void 0 ? void 0 : _a.pageInfo) === null || _b === void 0 ? void 0 : _b.totalPages]);
    var onOrderClickHandler = function (orderId) {
        window.location.href = "".concat(engineURL, "#/booking/").concat(orderId);
    };
    var orders = (_e = (_d = (_c = ordersData === null || ordersData === void 0 ? void 0 : ordersData.AviaOrdersListPaginated) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(function (edge) { return edge.node; })) !== null && _e !== void 0 ? _e : [];
    var noFlights = !orders.length && !ordersLoading;
    var isActiveFlights = activeTab.orderFlownStatus === OrderFlownStatus.Active;
    var isFlownFlights = activeTab.orderFlownStatus === OrderFlownStatus.Flown;
    return (React.createElement("div", { className: css.orders__wrapper },
        ordersLoading && React.createElement(SimpleLoader, null),
        companyInfo.webskySearchOrder && (React.createElement(Warnings, { className: css.warning },
            React.createElement("span", null,
                t('Orders that were booked on a previous version of the site are available'),
                "\u00A0",
                React.createElement(Link, { className: css.link, action: "".concat(companyInfo.webskySearchOrder), target: '_blank' }, t('this link'))))),
        React.createElement("div", { className: css.orders__header },
            React.createElement("h1", { className: cn(css.route__title, css.orders__title) }, t('Orders')),
            React.createElement(Tabs, { tabs: tabs, onChange: onTabChange, selected: activeTab.value, className: css.tabs, flexContainerClassName: css.tabs__flexContainer, tabClassName: css.tab, tabHeaderClassName: css.tabLabel, selectedTabClassName: css.tabSelected, indicatorClassName: css.tabIndicator, rootTabsClassName: css.tabs__container, variant: isTablet ? 'fullWidth' : 'standard', tabsAlign: isTablet ? 'center' : 'left' })),
        noFlights && isActiveFlights && (React.createElement("div", { className: css.noFlightsTitle }, t("You don't have any upcoming flights"))),
        noFlights && isFlownFlights && React.createElement("div", { className: css.noFlightsTitle }, t("You have no flights flown")),
        orders.map(function (order) {
            return (React.createElement(OrderCard, { key: order.id, order: order, isCheckinOpen: false, type: 'account', onClick: onOrderClickHandler, onDeleted: refetchOrders }));
        }),
        React.createElement(ProfileControls, { className: css.profileControls, onRefetchOrders: refetchOrders }),
        !!paginationAvailable && (React.createElement(WxPagination, { className: css.pagination, page: page, pages: pagesCount, onHandleChange: handleChangePage }))));
});
export default Orders;
