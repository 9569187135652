import cn from 'classnames';
import * as React from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../../utils';
import { CloseButton } from '../../../index';
initI18n('FullscreenDialog');
var FullscreenDialogClose = function (_a) {
    var text = _a.text, onClose = _a.onClose, closeClassName = _a.closeClassName;
    var theme = useTheme('FullscreenDialog').FullscreenDialog;
    var t = useTranslation('FullscreenDialog').t;
    return (React.createElement("div", { className: cn(theme.close, closeClassName), onClick: onClose },
        React.createElement("span", { className: theme.close__text }, text !== null && text !== void 0 ? text : t('Close')),
        React.createElement(CloseButton, { variant: "closeFullscreen", className: theme.close__icon })));
};
export default FullscreenDialogClose;
