import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Money } from '../../Money';
import { initI18n } from '../../utils';
import WarningModal from '../../WarningModal';
import { useTheme } from '../../theme';
import MediaQuery from '../../MediaQuery/MediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import { ArrowForward } from '../../Icons';
import ModalConfirm from '../../ModalConfirm';
import { BaseButton, IconButton } from '../../BaseComponents';
initI18n('Toolbar');
var lastErrorMessage = '';
var Toolbar = function (_a) {
    var _b, _c;
    var totalPrice = _a.totalPrice, onBackButtonClick = _a.onBackButtonClick, onButtonClick = _a.onButtonClick, additional = _a.additional, buttonDisabled = _a.buttonDisabled, actionsRight = _a.actionsRight, isLoading = _a.isLoading, error = _a.error, hideNextButton = _a.hideNextButton, onCloseErrorMsg = _a.onCloseErrorMsg, miles = _a.miles, className = _a.className, nextButtonClassName = _a.nextButtonClassName, priceInMiles = _a.priceInMiles, innerClassName = _a.innerClassName, onRetrySearch = _a.onRetrySearch;
    var css = useTheme('Toolbar').Toolbar;
    var t = useTranslation('Toolbar').t;
    // This prevents error message blinking.
    if (error && error.message) {
        lastErrorMessage = error.message;
    }
    var retryErrorMessages = ['No appropriate flights found on the segment'];
    var retryMessage = retryErrorMessages.find(function (it) { var _a; return (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.includes(it); });
    return (React.createElement("div", { className: cn(css.toolbar, className, 'mui-fixed'), id: "toolbar" },
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the creating order process. Please try again later or contact a customer support service.'), isOpen: !!error && !retryMessage, onButtonClick: onCloseErrorMsg, errorMessage: lastErrorMessage, onClose: onCloseErrorMsg, buttonText: t('OK, thank you') }),
        React.createElement(ModalConfirm, { title: t('Error...'), isOpen: !!error && !!retryMessage, onConfirm: function () {
                onCloseErrorMsg();
                onRetrySearch();
            }, onClose: onCloseErrorMsg, onCancel: onCloseErrorMsg, confirmBtn: t('Try again') },
            React.createElement("div", null, t(retryMessage))),
        React.createElement("div", { className: cn(css.inner, innerClassName) },
            React.createElement("div", { className: cn(css.actions, css.actions_left) },
                !!onBackButtonClick && (React.createElement("div", { className: css.backButton__wrapper, onClick: onBackButtonClick },
                    React.createElement(IconButton, { className: css.backButton }, ArrowForward),
                    React.createElement("span", null, t('Back')))),
                additional),
            React.createElement("div", { className: cn(css.actions, (_b = {}, _b[css.actions_right] = actionsRight, _b)) },
                totalPrice && !!totalPrice.amount && (React.createElement("span", { className: css.price },
                    React.createElement(MediaQuery, { minWidth: "mobile" },
                        t('Total'),
                        ": ",
                        React.createElement(Money, { money: totalPrice }),
                        (miles > 0 || priceInMiles > 0) && (React.createElement("div", { className: css.miles },
                            React.createElement(Money, { money: {
                                    amount: priceInMiles > 0 ? priceInMiles : miles,
                                    currency: 'miles'
                                }, showCurrency: false, moneyClassName: css.miles__money }),
                            t(priceInMiles > 0 ? 'miles will be charged' : 'miles will be awared'),
                            React.createElement(Tooltip, { title: t(priceInMiles > 0
                                    ? 'This flight will be partially paid by miles'
                                    : 'Fly with us and get miles') },
                                React.createElement("div", { className: css.miles__hint }))))))),
                !hideNextButton && (React.createElement(BaseButton, { className: cn(css.button, nextButtonClassName, (_c = {}, _c[css.button_disabled] = buttonDisabled, _c)), onClick: function (e) {
                        onButtonClick === null || onButtonClick === void 0 ? void 0 : onButtonClick(e);
                    }, variant: "action", isLoading: isLoading, disabled: buttonDisabled, type: onButtonClick ? 'button' : 'submit', "data-next-button": true }, t('Continue')))))));
};
export default Toolbar;
