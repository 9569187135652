import * as React from 'react';
import { CartService } from '../../Cart/types';
import { GdsServiceProductStatus, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import BaggageIcon from '../../Cart/BaggageIcon/BaggageIcon';
import { useConfig } from '../../context';
import { isPetBaggage } from '../../Checkout/utils';
import CommonSummary from './CommonSummary';
import { getTotalPrice } from '../../utils';
import { getBaggageName } from '../utils';
var BaggageSummary = function (props) {
    var availableAnimal = useConfig().global.servicesAvailable.animal;
    var onServiceDeleteHandler = function (travellerId, serviceId, segmentId, allowedSegments, allSegments, count) {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Baggage, {
            passengerId: travellerId,
            serviceId: serviceId,
            segmentId: segmentId,
            allSegments: allSegments,
            allowedSegments: allowedSegments,
            count: count
        });
    };
    var itemFactory = function (_a) {
        var selectedService = _a.selectedService, relatedOrderService = _a.relatedOrderService, travellerId = _a.travellerId, key = _a.key, groupType = _a.groupType;
        var name;
        var resultCount;
        var price;
        var included = selectedService.confirmedCount === selectedService.count;
        switch (groupType) {
            case 'all':
                resultCount = selectedService.count;
                price = getTotalPrice(selectedService.products, function (product) { return product.price; });
                name = getBaggageName(relatedOrderService, selectedService.count);
                break;
            case 'booked':
                resultCount = selectedService.count - selectedService.confirmedCount;
                price = getTotalPrice(selectedService.products.filter(function (product) { return product.status === GdsServiceProductStatus.Booked; }), function (product) { return product.price; });
                name = getBaggageName(relatedOrderService, selectedService.count - selectedService.confirmedCount);
                break;
            case 'confirmed':
                resultCount = selectedService.confirmedCount;
                name = getBaggageName(relatedOrderService, selectedService.confirmedCount);
                price = getTotalPrice(selectedService.products.filter(function (product) { return product.status === GdsServiceProductStatus.Ticketed; }), function (product) { return product.price; });
                included = true;
                break;
        }
        var dCount = selectedService.count - selectedService.confirmedCount;
        var count = selectedService.count - dCount;
        return {
            id: selectedService.serviceId,
            count: resultCount,
            name: name,
            price: price,
            included: included,
            icon: !props.isAnimalBaggage ? React.createElement(BaggageIcon, { baggageType: relatedOrderService.baggageType }) : null,
            onDelete: groupType === 'confirmed'
                ? null
                : function () {
                    return onServiceDeleteHandler(travellerId, selectedService.serviceId, key, relatedOrderService.allowedSegments, key === 'allSegments', count);
                }
        };
    };
    return (React.createElement(CommonSummary, { travellersById: props.travellersById, flight: props.flight, groupType: OrderAdditionalServiceGdsServiceServiceType.Baggage, services: props.services, travellers: props.travellers, onServiceDelete: function () { return null; }, serviceOptionGroupFactory: function (_a) {
            var items = _a.items, travellerId = _a.travellerId;
            return {
                items: items,
                allSegments: true,
                headerType: 'travellerName',
                traveller: props.travellersById.get(travellerId)
            };
        }, includedOptionItemFactory: function (_a) {
            var included = _a.selectedService, key = _a.key, travellerId = _a.travellerId;
            if (!props.isAnimalBaggage) {
                var baggageService = included.service;
                return {
                    id: included.serviceId,
                    segment: key,
                    count: included.count,
                    name: getBaggageName(baggageService, included.count),
                    included: true,
                    icon: React.createElement(BaggageIcon, { baggageType: included.service['baggageType'] })
                };
            }
            return null;
        }, serviceOptionItemFactory: function (_a) {
            var _b, _c, _d;
            var selectedService = _a.selectedService, key = _a.key, travellerId = _a.travellerId;
            var relatedOrderService = (_d = (_c = (_b = props.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.find(function (service) { return service.id === selectedService.serviceId; });
            if (selectedService.confirmedCount && selectedService.count !== selectedService.confirmedCount) {
                return [
                    itemFactory({ selectedService: selectedService, travellerId: travellerId, key: key, relatedOrderService: relatedOrderService, groupType: 'confirmed' }),
                    itemFactory({ selectedService: selectedService, travellerId: travellerId, key: key, relatedOrderService: relatedOrderService, groupType: 'booked' })
                ];
            }
            return itemFactory({ selectedService: selectedService, travellerId: travellerId, key: key, relatedOrderService: relatedOrderService, groupType: 'all' });
        }, serviceFilterPredicate: function (service) {
            var baggageService = service;
            if (availableAnimal) {
                var petBaggage = isPetBaggage(baggageService);
                return props.isAnimalBaggage ? petBaggage : !petBaggage;
            }
            return true;
        } }, function (children) { return props.children(children); }));
};
export default BaggageSummary;
