import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Passengers from '../routes/Passengers';
import Segments from '../routes/Segments';
import { useSelector } from 'react-redux';
import { getOrder, isOpenedExchange } from '../../../store/order/selectors';
import SearchFlights from '../routes/SearchFlights';
import { useTheme } from '../../../../../theme';
import Request from '../routes/Request';
import Selections from './Selections/Selections';
import { useTranslation } from 'react-i18next';
import Payment from '../routes/Payment';
import Dialog from '../Dialog/Dialog';
import { getNewFlightId } from '../../../store/newFlights/selectors';
import { getSelectedPassengerIds } from '../../../store/passengers/selectors';
import { getFlightsToExchange } from '../../../store/selectors';
import WarningModal from '../../../../../WarningModal';
import { CloseClear } from '../../../../../Icons';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../../../utils';
import { ExchangeStep } from '../../../types';
import { useOrderQuery, ExareStatus } from '@websky/graphql';
import Reasons from '../routes/Reasons';
import MediaQuery from 'react-responsive';
import { useIataCode } from '../../../../../hooks';
import SimpleLoader from '../../../../../SimpleLoader';
import PromoLoader from '../../../../../PromoLoader';
var PaymentResult;
(function (PaymentResult) {
    PaymentResult["Success"] = "success";
    PaymentResult["Failed"] = "failed";
})(PaymentResult || (PaymentResult = {}));
initI18n('Exchange');
var Inner = function (props) {
    var orderQuery = useOrderQuery({
        variables: {
            id: props.orderId
        },
        skip: true,
        fetchPolicy: 'no-cache'
    });
    var IATA = useIataCode();
    var order = useSelector(getOrder);
    var match = useRouteMatch(), baseUrl = match.url;
    var history = useHistory();
    var location = useLocation();
    var openedExchange = useSelector(isOpenedExchange);
    var newFlightId = useSelector(getNewFlightId);
    var _a = __read(React.useState(false), 2), isLoading = _a[0], setLoading = _a[1];
    var selectedPassengers = useSelector(getSelectedPassengerIds);
    var selectedFlights = useSelector(getFlightsToExchange);
    var _b = __read(React.useState(null), 2), requestError = _b[0], setRequestError = _b[1];
    var _c = __read(React.useState(null), 2), paymentStatus = _c[0], setPaymentStatus = _c[1];
    var passengersPageURL = baseUrl.replace('exchange', 'order');
    var handleReturnToPassengers = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, (props === null || props === void 0 ? void 0 : props.onRefetchCheckoutOrder())];
                case 1:
                    _a.sent();
                    history.push(passengersPageURL);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var isRequestPage = location.pathname === "".concat(baseUrl, "/request");
    var t = useTranslation('Exchange').t;
    useEffect(function () {
        if (!order || order.id !== props.orderId) {
            setLoading(true);
            orderQuery.refetch().then(function (data) {
                props.setOrder(data.data.Order);
                setLoading(false);
            });
        }
        return function () {
            setLoading(false);
        };
    }, [order]);
    var refreshOrder = function (failedExchange) { return __awaiter(void 0, void 0, void 0, function () {
        var order, error_1, polling_1;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    setLoading(true);
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, orderQuery.refetch()];
                case 2:
                    order = _h.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _h.sent();
                    setRequestError(error_1);
                    return [3 /*break*/, 4];
                case 4:
                    if (!failedExchange) return [3 /*break*/, 8];
                    if (!((_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.data) === null || _a === void 0 ? void 0 : _a.Order) === null || _b === void 0 ? void 0 : _b.exareInfo) === null || _c === void 0 ? void 0 : _c.lastExchange)) return [3 /*break*/, 5];
                    props.setOrder(order.data.Order);
                    props.clearNewFlight();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, handleReturnToPassengers()];
                case 6:
                    _h.sent();
                    _h.label = 7;
                case 7:
                    setLoading(false);
                    setPaymentStatus(null);
                    return [2 /*return*/];
                case 8:
                    if (!(((_g = (_f = (_e = (_d = order === null || order === void 0 ? void 0 : order.data) === null || _d === void 0 ? void 0 : _d.Order) === null || _e === void 0 ? void 0 : _e.exareInfo) === null || _f === void 0 ? void 0 : _f.lastExchange) === null || _g === void 0 ? void 0 : _g.status) === ExareStatus.Finished)) return [3 /*break*/, 10];
                    props.setOrder(order.data.Order);
                    props.clearNewFlight();
                    return [4 /*yield*/, handleReturnToPassengers()];
                case 9:
                    _h.sent();
                    setLoading(false);
                    setPaymentStatus(null);
                    return [3 /*break*/, 11];
                case 10:
                    polling_1 = function (interval) { return __awaiter(void 0, void 0, void 0, function () {
                        var order;
                        var _a, _b, _c, _d;
                        return __generator(this, function (_e) {
                            switch (_e.label) {
                                case 0: return [4 /*yield*/, orderQuery.refetch()];
                                case 1:
                                    order = _e.sent();
                                    if (!(((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.data) === null || _a === void 0 ? void 0 : _a.Order) === null || _b === void 0 ? void 0 : _b.exareInfo) === null || _c === void 0 ? void 0 : _c.lastExchange) === null || _d === void 0 ? void 0 : _d.status) === ExareStatus.Finished)) return [3 /*break*/, 3];
                                    props.setOrder(order.data.Order);
                                    props.clearNewFlight();
                                    return [4 /*yield*/, handleReturnToPassengers()];
                                case 2:
                                    _e.sent();
                                    setLoading(false);
                                    setPaymentStatus(null);
                                    return [3 /*break*/, 4];
                                case 3:
                                    setTimeout(function () { return polling_1(interval * 1.5); }, interval * 1.5);
                                    _e.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); };
                    setTimeout(function () { return polling_1(500); }, 500);
                    _h.label = 11;
                case 11:
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (paymentStatus === PaymentResult.Success) {
            refreshOrder();
        }
    }, [paymentStatus]);
    var setStep = function (newStep) {
        if (newStep === ExchangeStep.Segments) {
            history.push("".concat(baseUrl, "/segments"));
        }
        else if (newStep === ExchangeStep.NewFlights) {
            history.push("".concat(baseUrl, "/flights"));
        }
        else if (newStep === ExchangeStep.Request) {
            history.push("".concat(baseUrl, "/request"));
        }
        else if (newStep === ExchangeStep.Passengers) {
            history.push("".concat(baseUrl, "/passengers"));
        }
        else if (newStep === ExchangeStep.Payment) {
            history.push("".concat(baseUrl, "/payment"));
        }
        else if (newStep === ExchangeStep.Reasons) {
            history.push("".concat(baseUrl, "/reasons"));
        }
    };
    var theme = useTheme('Exchange').Inner;
    var successPayment = function () { return setPaymentStatus(PaymentResult.Success); };
    var failedPayment = function () { return setPaymentStatus(PaymentResult.Failed); };
    var setExchangeSuccess = function () {
        history.push("".concat(baseUrl, "/payment/success"));
    };
    if (!order) {
        return React.createElement(PromoLoader, null);
    }
    return (React.createElement("div", { className: theme.exchange },
        paymentStatus === PaymentResult.Success && (React.createElement(Dialog, { text: t('Successful payment'), acceptText: t('OK'), onAccept: function () { return setPaymentStatus(null); }, iconClassName: theme.dialog__paymentIcon, open: true })),
        paymentStatus === PaymentResult.Failed && (React.createElement(Dialog, { text: t('Failed payment'), acceptText: t('OK'), onAccept: function () { return setPaymentStatus(null); }, iconClassName: theme.dialog__failedIcon, open: true })),
        isLoading && React.createElement(SimpleLoader, null),
        requestError && React.createElement(WarningModal, { title: t('Oops...'), content: requestError }),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: theme.header },
                React.createElement("div", { className: theme.header__inner },
                    React.createElement("div", { className: theme.header__text }, t('Change ticket')),
                    React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH }, !isRequestPage && !openedExchange && React.createElement(Selections, { setStep: setStep })),
                    React.createElement("div", { className: theme.close },
                        React.createElement("div", { className: theme.close__text }, t('Back to order')),
                        React.createElement("span", { onClick: handleReturnToPassengers, className: theme.closeIcon }, CloseClear)))),
            React.createElement("div", { className: theme.container },
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH }, !isRequestPage && !openedExchange && React.createElement(Selections, { setStep: setStep })),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "".concat(baseUrl, "/passengers"), render: function () {
                            return !openedExchange ? (React.createElement(Passengers, { setStep: setStep, skipPassengersStep: IATA === 'DV' })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/request") }));
                        } }),
                    React.createElement(Route, { path: "".concat(baseUrl, "/reasons"), render: function () {
                            return selectedPassengers.length ? (React.createElement(Reasons, { setStep: setStep, passengersPageURL: passengersPageURL })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                        } }),
                    React.createElement(Route, { path: "".concat(baseUrl, "/segments"), render: function () {
                            return selectedPassengers.length ? (React.createElement(Segments, { setStep: setStep, passengersPageURL: passengersPageURL })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                        } }),
                    React.createElement(Route, { path: "".concat(baseUrl, "/flights"), render: function () {
                            return selectedPassengers.length && selectedFlights.flightsByIds.length ? (React.createElement(SearchFlights, { setStep: setStep, setLoading: setLoading })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                        } }),
                    React.createElement(Route, { path: "".concat(baseUrl, "/request"), render: function () {
                            var _a;
                            return ((_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) || newFlightId ? (React.createElement(Request, { setStep: setStep, clearSelectedFares: props.clearNewFlight, setExchangeSuccess: setExchangeSuccess, refreshOrder: refreshOrder, newFlightId: newFlightId, goToOrder: handleReturnToPassengers })) : (React.createElement(Redirect, { to: "".concat(baseUrl, "/passengers") }));
                        } }),
                    React.createElement(Route, { exact: true, path: "".concat(baseUrl, "/payment"), render: function () { return (React.createElement(Payment, { setStep: setStep, successPayment: successPayment, failedPayment: failedPayment, setLoading: setLoading })); } }),
                    React.createElement(Route, { path: "".concat(baseUrl, "/payment/success"), render: function () {
                            setPaymentStatus(PaymentResult.Success);
                            return React.createElement(Redirect, { to: "".concat(baseUrl, "/request") });
                        } }),
                    React.createElement(Route, { path: "".concat(baseUrl, "/payment/failed"), render: function () {
                            setPaymentStatus(PaymentResult.Failed);
                            return React.createElement(Redirect, { to: "".concat(baseUrl, "/request") });
                        } }),
                    React.createElement(Route, { render: function () { return React.createElement(Redirect, { to: "".concat(baseUrl, "/request") }); } }))))));
};
export default Inner;
