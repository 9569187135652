var _this = this;
import { __read } from "tslib";
import * as React from 'react';
export var VarioqubCounter = function (id) {
    return "(function(e, x, pe, r, i, me, nt){\n\t\te[i]=e[i]||function(){(e[i].a=e[i].a||[]).push(arguments)},\n\t\tme=x.createElement(pe),me.async=1,me.src=r,nt=x.getElementsByTagName(pe)[0],nt.parentNode.insertBefore(me,nt)})\n\t\t(window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');\n\t\tymab('metrika.".concat(id, "', 'init',  function (resp) {\n\t\t\tlocalStorage.setItem('varioqub',JSON.stringify(resp));window.dispatchEvent( new CustomEvent(\"varioqubStorageChanged\",{detail:{resp:resp}} ) );\n\t\t});");
};
export var useVarioqub = function () {
    var _a = __read(React.useState(null), 2), varioqub = _a[0], setVarioqub = _a[1];
    window.addEventListener('varioqubStorageChanged', function (e) {
        setVarioqub(e.detail.resp);
        window.removeEventListener('varioqubStorageChanged', _this);
    });
    return varioqub;
};
export var VarioqubContext = React.createContext(null);
