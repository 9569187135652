import { __assign } from "tslib";
import * as React from 'react';
import { BaggageType as BaggageTypeEnum } from '@websky/graphql';
import Baggage from '../../../../Baggage';
import { BaseButton } from '../../../../../index';
import { useTheme } from '../../../../../theme';
import cn from 'classnames';
import Money from '../../../../../Money';
import { useIsMobile } from '../../../../../utils';
var MobileBaggageSelector = function (props) {
    var _a;
    var theme = useTheme('UpgradeBaggageDialog').MobileBaggageSelector;
    var selectedService = React.useMemo(function () {
        var _a;
        return (_a = props.services.find(function (services) { return services[0].rfics === props.selectedBaggageId; })) === null || _a === void 0 ? void 0 : _a[0];
    }, [props.services, props.selectedBaggageId]);
    var price = React.useMemo(function () {
        if (props.allSegments) {
            var amount_1 = 0;
            props.segments.forEach(function (segment) {
                if (selectedService.segmentIds.some(function (segments) { return segments.includes(segment.id); })) {
                    amount_1 += selectedService.price.amount;
                }
            });
            return __assign(__assign({}, selectedService.price), { amount: amount_1 });
        }
        return selectedService.price;
    }, [selectedService, props.segments, props.allSegments]);
    var isMobile = useIsMobile();
    var isBaggageExcess = selectedService.type === BaggageTypeEnum.BaggageExcess;
    return (React.createElement("div", null,
        selectedService && React.createElement("div", { className: theme.description }, selectedService.name),
        props.services.length === 1 && (React.createElement("div", { className: cn(theme.container, (_a = {},
                _a[theme === null || theme === void 0 ? void 0 : theme[props.services[0][0].type]] = theme === null || theme === void 0 ? void 0 : theme[props.services[0][0].type],
                _a)) },
            React.createElement(Baggage, { baggage: props.services[0][0], selected: false, onlyIcon: !isMobile || (isMobile && !isBaggageExcess), short: true }),
            React.createElement("div", { className: theme.description }, props.services[0][0].size))),
        props.services.length > 1 && (React.createElement("div", { className: theme.container },
            React.createElement(Baggage, { baggage: props.services[0][0], selected: false, short: true, iconClassName: theme.icon, onlyIcon: true }),
            props.services.map(function (service) {
                var _a;
                return (React.createElement(BaseButton, { variant: "smallNeitral", className: cn(theme.selector, (_a = {},
                        _a[theme.selected] = service[0].rfics === props.selectedBaggageId,
                        _a)), onClick: function () { return props.setSelectedBaggageId(service[0].rfics); } },
                    service[0].value.amount,
                    " ",
                    service[0].value.measurement));
            }))),
        React.createElement("div", { className: theme.footer },
            React.createElement("div", null, selectedService.description),
            React.createElement(Money, { moneyClassName: theme.price, money: price }))));
};
export default MobileBaggageSelector;
