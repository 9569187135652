import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AiPredictionItemWrapper from '../AiPredictionItemWrapper/AiPredictionItemWrapper';
import { useTheme } from '../../../theme';
import { getDurationInSeconds } from '../../../Results/utils';
var AiPurchasePredictionMeal = function (_a) {
    var order = _a.order, meal = _a.meal, onChange = _a.onChange, onDisagree = _a.onDisagree, onAgree = _a.onAgree;
    var theme = useTheme('AIPurchasePredictionService').AiPredictionItemWrapper;
    var t = useTranslation('AIPurchasePredictionService').t;
    var time = useMemo(function () {
        var maxDurationInMinutes = 0;
        order.flight.segments.forEach(function (segment) {
            var inSeconds = getDurationInSeconds(order.flight.segments[0].segment.duration);
            var inMinutes = inSeconds / 60;
            if (inMinutes > maxDurationInMinutes) {
                maxDurationInMinutes = inSeconds;
            }
        });
        return Math.floor(maxDurationInMinutes / 60);
    }, [order]);
    var headerTitle = time > 0
        ? t('YourFlightHours', { hours: time, plural: t('hours', { count: time }) })
        : t('Order your meals in advance');
    return (React.createElement(AiPredictionItemWrapper, { service: {
            price: meal.price,
            name: meal.description,
            renderHead: function () {
                return React.createElement("img", { src: meal.image, alt: meal.name, className: theme.predictionCard__meal });
            }
        }, buttonTitle: t('Continue without meal'), headerTitle: headerTitle, onDisagree: onDisagree, onAgree: onAgree, onChange: onChange }));
};
export default AiPurchasePredictionMeal;
