import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import TravelCompanions from '../../../TravelCompanions';
import { renderType } from '../../../TravelCompanions/components/TravelCompanions';
import AccountMethods from '../AuthMethods/AuthMethods';
import SocialAuth from '../SocialAuth';
import { useConfig } from '../../../context';
import { OauthServiceType, LoginType, useGetUserSettingsQuery, useRemoveAuthMethodMutation, useSocialAuthQuery } from '@websky/graphql';
import { getBackRedirectUrl, setSocialAuthRedirectUrl } from '../../../SocialAuth/components/utils';
import { useTinkoffCallback } from '../../../SocialAuth/components/hooks';
import DeleteAccount from '../DeleteAccount/DeleteAccount';
import MailSettings from '../MailSettings/MailSettings';
var Settings = function (_a) {
    var _b, _c, _d, _e, _f;
    var currentUser = _a.currentUser, setUserSettingsError = _a.setUserSettingsError, refetchAccountInfo = _a.refetchAccountInfo;
    var theme = useTheme('AccountModule').Account;
    var t = useTranslation('Account').t;
    var socialAuthMethods = useConfig().global.socialAuthMethods;
    var isSocialAccountsEnabled = socialAuthMethods.some(function (socialAuth) { return socialAuth.isAvailable === true; });
    var oAuth = useSocialAuthQuery({
        skip: true
    });
    var _g = __read(useRemoveAuthMethodMutation(), 1), removeMethod = _g[0];
    var userSettings = useGetUserSettingsQuery();
    var userEmail = (_b = currentUser.userProfile.values.find(function (item) { return item.name === 'email'; })) === null || _b === void 0 ? void 0 : _b.value;
    var isSubscriptionCheckboxVisible = !!((_e = (_d = (_c = userSettings === null || userSettings === void 0 ? void 0 : userSettings.data) === null || _c === void 0 ? void 0 : _c.UserSettings) === null || _d === void 0 ? void 0 : _d.find(function (setting) { return (setting === null || setting === void 0 ? void 0 : setting.name) === 'Subscribe'; })) === null || _e === void 0 ? void 0 : _e.value) && !!userEmail;
    var socialServices = [
        LoginType.Google,
        LoginType.Facebook,
        LoginType.TinkoffID,
        LoginType.Vkontakte
    ];
    var onRemoveAuthMethod = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, removeMethod({
                            variables: {
                                params: id
                            }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors === null || errors === void 0 ? void 0 : errors.length) {
                        setUserSettingsError(errors[0]);
                    }
                    else if (data.DeleteAuthMethod && data.DeleteAuthMethod.result) {
                        refetchAccountInfo();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    setUserSettingsError(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var oAuthRedirect = function (buttonName) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, errors, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, oAuth.refetch({
                            params: {
                                service: OauthServiceType[buttonName],
                                backRedirectUrl: getBackRedirectUrl(OauthServiceType[buttonName])
                            }
                        })];
                case 1:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    setSocialAuthRedirectUrl(OauthServiceType[buttonName]);
                    if (errors && errors.length) {
                        setUserSettingsError(errors[0]);
                    }
                    else if (data.OauthRedirectUrl.result) {
                        window.open(data.OauthRedirectUrl.message, '_top');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _b.sent();
                    setUserSettingsError(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var activityStatuses = React.useMemo(function () {
        return currentUser === null || currentUser === void 0 ? void 0 : currentUser.authMethods.filter(function (item) { return socialServices.includes(item.loginType); }).map(function (item) {
            return {
                service: item.loginType,
                status: item.confirmed,
                id: item.id,
                userName: currentUser === null || currentUser === void 0 ? void 0 : currentUser.userProfile.values.filter(function (item) { return item.name === 'firstName'; })[0].value
                // Будет нормальный ответ из авторизации, сейчас интерфейс не подходящий
            };
        });
    }, [currentUser]);
    useTinkoffCallback(refetchAccountInfo);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: theme.route__title }, t('Profile')),
        React.createElement(TravelCompanions, { format: renderType.Passengers }),
        React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.container__title }, t('Login settings')),
            React.createElement("div", { className: theme.container__content },
                React.createElement(AccountMethods, null),
                isSocialAccountsEnabled && (React.createElement(SocialAuth, { onDelete: onRemoveAuthMethod, onAuthRedirect: oAuthRedirect, socialAuthMethods: socialAuthMethods, authMethods: activityStatuses })))),
        isSubscriptionCheckboxVisible && (React.createElement("div", { className: theme.container },
            React.createElement("div", { className: theme.container__title }, t('Mail settings')),
            React.createElement("div", { className: theme.container__content },
                React.createElement(MailSettings, { onError: function () { return null; }, userSettings: (_f = userSettings === null || userSettings === void 0 ? void 0 : userSettings.data) === null || _f === void 0 ? void 0 : _f.UserSettings, userEmail: userEmail, refetchUserSettings: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, (userSettings === null || userSettings === void 0 ? void 0 : userSettings.refetch())];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    }); }); } })))),
        React.createElement("div", { className: theme.footer },
            React.createElement(DeleteAccount, { onError: function () { return null; } }))));
};
export default Settings;
