import { __assign, __read } from "tslib";
import cn from 'classnames';
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { addPassenger, removePassenger, setPassengers } from '../../../store/passengers/actions';
import { getPassengersState, getPassengersTitle, getTotalPassengersCount } from '../../../store/passengers/selectors';
import { getSegments, isMultiCity } from '../../../store/segments/selectors';
import Dropdown from '../../../../Dropdown';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import { SlideBottom } from '../../../../SlideBottom/components/SlideBottom';
import { Passengers as PassengersComponent } from '../../../../Passengers/components/Passengers';
import { useConfig } from '../../../../context';
import { useTheme } from '../../../../theme';
import { Tooltip } from '../../../../index';
import { canBeDecrease, canBeIncrease, isRoutesMatch } from './utils';
import { useRenderers } from '../../../../renderProps';
import { useIsWithSelectableCurrencies } from '../hooks';
import { getRedeemMiles } from '../../../../SearchForm/store/selectors';
var arrowIcon = (React.createElement("svg", { width: "10", height: "5", viewBox: "0 0 10 5", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M0 0L5 5L10 0L0 0Z", fill: "currentColor" })));
var passengerIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.5" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 12C13.66 12 15 10.66 15 9C15 7.34 13.66 6 12 6C10.34 6 9 7.34 9 9C9 10.66 10.34 12 12 12ZM12 13.9C10 13.9 6 15 6 17V18H18V17C18 15 14 13.9 12 13.9Z", fill: "white" }))));
var Passengers = memo(function (_a) {
    var _b, _c, _d;
    var isMultiCity = _a.isMultiCity, passengersTitle = _a.passengersTitle, passengers = _a.passengers, setPassengers = _a.setPassengers, addPassenger = _a.addPassenger, removePassenger = _a.removePassenger, passengersCount = _a.passengersCount, segments = _a.segments, redeemMiles = _a.redeemMiles;
    var css = useTheme('SearchForm').Passengers;
    var alternative = useConfig().SearchForm.mobileFormType !== 'common';
    var withSelectableCurrencies = useIsWithSelectableCurrencies();
    var _e = __read(useState(false), 2), isOpen = _e[0], setIsOpen = _e[1];
    var t = useTranslation('SearchForm').t;
    var buttonRef = useRef();
    var open = useCallback(function () { return setIsOpen(!isOpen); }, [isOpen]);
    var close = useCallback(function () { return setIsOpen(false); }, []);
    var focus = useCallback(function (event) { return event.target.blur(); }, []);
    var onClose = useCallback(function (event) {
        if (event.target !== buttonRef.current) {
            close();
        }
    }, [buttonRef]);
    var _f = useConfig().SearchForm, maxPassengersCount = _f.maxPassengersCount, passengersConfig = _f.passengersConfig;
    var filterPassengers = function () {
        var state = __assign({}, passengers);
        passengersConfig
            .filter(function (it) { return it.blockPayByMiles; })
            .map(function (it) {
            state[it.code] = 0;
        });
        setPassengers(state);
    };
    React.useEffect(function () {
        if (redeemMiles) {
            filterPassengers();
        }
    }, [redeemMiles]);
    var passengerSchemas = React.useMemo(function () {
        return passengersConfig
            .filter(function (passenger) {
            var _a;
            var _b;
            if (passenger.disabled || (redeemMiles && (passenger === null || passenger === void 0 ? void 0 : passenger.blockPayByMiles))) {
                return false;
            }
            if (!passenger.onlyRoutes && !passenger.disabledRoutes) {
                return true;
            }
            var isRouteAvailable = true;
            if ((_b = passenger === null || passenger === void 0 ? void 0 : passenger.disabledRoutes) === null || _b === void 0 ? void 0 : _b.some(function (routes) {
                if (routes.length === segments.length) {
                    return routes.every(function (route, index) { return isRoutesMatch(route, segments[index]); });
                }
                return false;
            })) {
                isRouteAvailable = false;
            }
            else if (passenger === null || passenger === void 0 ? void 0 : passenger.onlyRoutes) {
                isRouteAvailable = passenger.onlyRoutes.some(function (routes) {
                    if (routes.length === segments.length) {
                        return routes.every(function (route, index) { return isRoutesMatch(route, segments[index]); });
                    }
                    return false;
                });
            }
            if (isRouteAvailable) {
                return true;
            }
            else {
                if (passengers[passenger.code] > 0) {
                    setPassengers(__assign(__assign({}, passengers), (_a = {}, _a[passenger.code] = 0, _a)));
                }
                return false;
            }
        })
            .map(function (passenger) {
            return {
                type: passenger.code,
                count: passengers[passenger.code] || 0,
                canBeIncreased: canBeIncrease(passenger, passengersConfig, passengers, passengersCount, maxPassengersCount),
                canBeDecreased: canBeDecrease(passenger, passengersConfig, passengers)
            };
        });
    }, [passengersConfig, passengers, segments, redeemMiles]);
    var SearchFormPassengersCloseBtn = useRenderers().SearchFormPassengersCloseBtn;
    return (React.createElement("div", { className: cn(css.passengers, (_b = {},
            _b[css.passengers_isMultiCity] = isMultiCity,
            _b[css.passengers_withCurrency] = withSelectableCurrencies,
            _b[css.alternative] = alternative,
            _b)) },
        React.createElement(Tooltip, { title: t('Please select passengers'), open: passengersCount === 0, interactive: false },
            React.createElement("button", { type: "button", ref: buttonRef, className: cn(css.selector, (_c = {}, _c[css.selector_focused] = isOpen, _c)), onClick: open, onFocus: focus },
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement("span", { className: css.passengerIcon }, passengerIcon)),
                React.createElement("span", { className: css.title }, passengersTitle),
                React.createElement("span", { className: cn(css.arrowIcon, (_d = {}, _d[css.arrowIcon_flipped] = isOpen, _d)) }, arrowIcon))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Dropdown, { isOpen: isOpen, onClose: onClose },
                React.createElement("div", { className: css.menu },
                    React.createElement(PassengersComponent, { passengers: passengerSchemas, removePassenger: removePassenger, addPassenger: addPassenger })))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: isOpen, onClose: close },
                React.createElement("div", { className: css.mobileMenu },
                    React.createElement("h3", { className: css.mobileTitle },
                        t('Passengers'),
                        SearchFormPassengersCloseBtn && React.createElement(SearchFormPassengersCloseBtn, { close: close })),
                    React.createElement(PassengersComponent, { passengers: passengerSchemas, removePassenger: removePassenger, addPassenger: addPassenger }))))));
});
var mapStateToProps = function (state) {
    return {
        isMultiCity: isMultiCity(state),
        passengersTitle: getPassengersTitle(state),
        passengers: getPassengersState(state),
        passengersCount: getTotalPassengersCount(state),
        segments: getSegments(state),
        redeemMiles: getRedeemMiles(state)
    };
};
var mapDispatchToProps = {
    addPassenger: addPassenger,
    removePassenger: removePassenger,
    setPassengers: setPassengers
};
export default connect(mapStateToProps, mapDispatchToProps)(Passengers);
