import { __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useToggleable } from '../../../hooks';
import { useIsMobile } from '../../../utils';
import { useSelectCancelSegments } from '../SelectCancelSegments/hooks';
import SendToEmail from '../SendToEmail/SendToEmail';
import Button from '../../../Button';
import CancelModal from '../CancelModal/CancelModal';
import Modal from '../../../Modal';
import Tooltip from '../../../Tooltip';
import SelectSegments from '../../../SelectSegments';
import SelectCancelSegments from '../SelectCancelSegments/SelectCancelSegments';
import { Mail, Refundable } from '../../../Icons';
var Footer = function (_a) {
    var _b;
    var className = _a.className, isCheckedIn = _a.isCheckedIn, canDownloadBoardingPass = _a.canDownloadBoardingPass, segments = _a.segments, retryLink = _a.retryLink, passengerName = _a.passengerName, onCheckinCancel = _a.onCheckinCancel, onTicketsResend = _a.onTicketsResend;
    var css = useTheme('RegisteredPassenger').Footer;
    var t = useTranslation('RegisteredPassenger').t;
    var _c = useToggleable(false), isOpenCancelModal = _c.isOpen, closeCancelModal = _c.close, toggleCancelModal = _c.toggle;
    var isMobile = useIsMobile();
    var _d = __read(useState(null), 2), ticketResendEmail = _d[0], setTicketResendEmail = _d[1];
    var _e = __read(useState(false), 2), ticketResendLoading = _e[0], setTicketResendLoading = _e[1];
    var _f = useSelectCancelSegments(onCheckinCancel), isSelectSegmentsModalOpen = _f.isSelectSegmentsModalOpen, selectSegmentToCancel = _f.selectSegmentToCancel, openSelectSegmentsToCancelModal = _f.openSelectSegmentsModal, onConfirmCancel = _f.onConfirm, selectedSegmentsToCancel = _f.selectedSegmentsToCancel, closeSelectSegmentsToCancelModal = _f.closeSelectSegmentsModal, isCancelAvailableForMultipleSegments = _f.isCancelAvailableForMultipleSegments;
    var toggleCancelModalHandler = useCallback(function () {
        if (isCancelAvailableForMultipleSegments) {
            openSelectSegmentsToCancelModal();
        }
        // cancel only for one segment
        if (!isCancelAvailableForMultipleSegments) {
            toggleCancelModal();
        }
    }, [isOpenCancelModal, isCancelAvailableForMultipleSegments]);
    var cancelAvailable = useMemo(function () { return segments.some(function (segment) { var _a; return (_a = segment.checkinInfo) === null || _a === void 0 ? void 0 : _a.cancelAvailable; }); }, [segments]);
    var onTicketsResendHandler = function (email, segmentId, onClose) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setTicketResendLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onTicketsResend(email, segmentId)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4:
                    onClose === null || onClose === void 0 ? void 0 : onClose();
                    setTicketResendLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onResendTicketsHandler = function (onClick) { return function (email, segmentId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setTicketResendEmail(email);
                    return [4 /*yield*/, onClick(email, segmentId)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }; };
    var onCheckinCancelHandler = function () {
        onCheckinCancel([]);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(css.container, className, (_b = {},
                _b[css.disabled] = !isCheckedIn,
                _b)) }, isCheckedIn ? (React.createElement(React.Fragment, null,
            cancelAvailable && (React.createElement(Button, { variant: "text", className: cn(css.button, css.cancel), onClick: toggleCancelModalHandler },
                React.createElement("div", { className: css.button__icon }, Refundable),
                t('Cancel check-in'))),
            canDownloadBoardingPass && onTicketsResend && (React.createElement(SelectSegments, { title: t('Select flight for download'), segments: segments, onClick: onTicketsResendHandler, renderTrigger: function (_a) {
                    var onClick = _a.onClick;
                    return (React.createElement(SendToEmail, { changeLoadingStatus: segments.length === 1, onResendTickets: onResendTicketsHandler(onClick), renderTrigger: function (onClick) { return (React.createElement(Button, { variant: "text", className: css.button, onClick: onClick },
                            React.createElement("div", { className: css.button__icon }, Mail),
                            t('Send to email'))); } }));
                }, renderButton: function (segment, onClose) {
                    var canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                    return canDownloadBoardingPass ? (React.createElement(Button, { className: css.sendToEmailButton, onClick: function () {
                            return onTicketsResendHandler(ticketResendEmail, segment.id, onClose);
                        }, isLoading: ticketResendLoading },
                        React.createElement("div", { className: css.button__icon }, Mail),
                        isMobile ? t('Send') : t('Send to email'))) : (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                        React.createElement(Button, { className: cn(css.sendToEmailButton, css.sendToEmailButton_disabled), onClick: function () { return null; } },
                            React.createElement("div", { className: css.button__icon }, Mail),
                            isMobile ? t('Send') : t('Send to email'))));
                } })))) : (React.createElement(NavLink, { to: retryLink, target: "_blank" },
            React.createElement(Button, { variant: isMobile && !isCheckedIn ? 'common' : 'text', className: css.button }, t('Retry check-in'))))),
        React.createElement(Modal, { open: isOpenCancelModal, onClose: closeCancelModal, maxWidth: "xs", classes: { scrollBody: css.scrollBody, paper: css.paper }, slideUp: isMobile },
            React.createElement(CancelModal, { onClose: closeCancelModal, onConfirm: onCheckinCancelHandler, passengerName: passengerName })),
        React.createElement(SelectCancelSegments, { onConfirm: onConfirmCancel, onClose: closeSelectSegmentsToCancelModal, onSegmentSelect: selectSegmentToCancel, selectedSegments: new Set(selectedSegmentsToCancel.map(function (segment) { return segment.id; })), open: isSelectSegmentsModalOpen })));
};
export default Footer;
