import { __assign, __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
export var useSwipeDown = function (_a) {
    var onClose = _a.onClose, isMobile = _a.isMobile, isOpen = _a.isOpen, scroll = _a.scroll;
    var _b = __read(useState(false), 2), isScrolled = _b[0], setIsScrolled = _b[1];
    var _c = __read(useState(0), 2), delta = _c[0], setDelta = _c[1];
    var _d = __read(useState(onClose && isMobile), 2), swipeFlag = _d[0], setSwipeFlag = _d[1];
    var paperRef = useRef(null);
    var swipeBlockRef = useRef(null);
    var swipeFxRef = useRef(null);
    var contentRef = useRef(null);
    var dialogRef = useRef(null);
    var swipeableConfig = {
        delta: 10,
        preventScrollOnSwipe: true,
        trackTouch: true,
        trackMouse: true,
        rotationAngle: 0,
        swipeDuration: Infinity,
        touchEventOptions: { passive: false } // options for touch listeners (*See Details*)
    };
    var setInitialStyles = function () {
        if (paperRef.current) {
            paperRef.current.style.transform = 'translateY(0)';
        }
        if (swipeFxRef.current) {
            swipeFxRef.current.style.transform = 'scale(1)';
        }
    };
    var onScroll = function (e) {
        setIsScrolled(e.target.scrollTop > 0);
    };
    useEffect(function () {
        var _a;
        if (dialogRef.current && isOpen && onClose) {
            setInitialStyles();
            (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', onScroll);
            handlers.ref(scroll === 'body' ? swipeBlockRef.current : paperRef.current);
        }
        return function () { var _a; return (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', onScroll); };
    }, [isOpen, isMobile, onClose, dialogRef, contentRef]);
    var handlers = useSwipeable(__assign({ onSwipedDown: function () {
            if (!swipeFlag || !isMobile || !onClose) {
                return;
            }
            if (delta > 70) {
                onClose();
            }
        }, onSwipeStart: function (data) {
            setSwipeFlag(!(contentRef.current.contains(data.event.target) && isScrolled && onClose));
        }, onTouchStartOrOnMouseDown: function (data) {
            setSwipeFlag(!(contentRef.current.contains(data.event.target) && isScrolled && onClose));
        }, onTouchEndOrOnMouseUp: function () {
            if (!swipeFlag || !isMobile || !onClose) {
                return;
            }
            if (delta < 70) {
                setInitialStyles();
            }
            setSwipeFlag(!(isScrolled && onClose));
        }, onSwiping: function (data) {
            if (!swipeFlag || !isMobile || !onClose) {
                return;
            }
            setDelta(data.deltaY);
            if (data.deltaY > 0) {
                if (paperRef.current) {
                    paperRef.current.style.transform = "translateY(".concat(data.deltaY, "px)");
                }
                if (swipeFxRef.current) {
                    swipeFxRef.current.style.transform =
                        data.deltaY <= 70 ? "scale(".concat(1 - data.deltaY / 70, ")") : 'scale(0)';
                }
            }
        } }, swipeableConfig));
    return { handlers: handlers, dialogRef: dialogRef, contentRef: contentRef, swipeBlockRef: swipeBlockRef, swipeFxRef: swipeFxRef, paperRef: paperRef };
};
export var useModalXTweak = function (open) {
    var _a = __read(React.useState(false), 2), isOpenTweak = _a[0], setIsOpenTweak = _a[1];
    var openTweak = function (e) {
        if (e.code === 'Space' && e.shiftKey) {
            setIsOpenTweak(!isOpenTweak);
            window.removeEventListener('keydown', openTweak);
            open === null || open === void 0 ? void 0 : open();
        }
    };
    window.addEventListener('keydown', openTweak);
    return isOpenTweak;
};
