import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Money from '../../Money';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import { useRenderers } from '../../renderProps';
import { BaseButton } from '../../index';
initI18n('CheckoutAdditionalService');
var CheckoutAdditionalService = function (props) {
    var _a;
    var _b, _c, _d, _e, _f, _g;
    var theme = useTheme('CheckoutAdditionalService').CheckoutAdditionalService;
    var t = useTranslation('CheckoutAdditionalService').t;
    var renderCheckoutAdditionalServiceHeader = useRenderers().renderCheckoutAdditionalServiceHeader;
    return (React.createElement("div", { className: cn(theme.service, props.className, (_a = {}, _a[theme.service_active] = props.isSelected, _a)), style: props.style, "data-insurance-code": props.insuranceCode, "data-rfisc": props.dataRfisc }, (_b = renderCheckoutAdditionalServiceHeader === null || renderCheckoutAdditionalServiceHeader === void 0 ? void 0 : renderCheckoutAdditionalServiceHeader({
        header: props.header,
        icon: props.icon,
        priceFrom: props.priceFrom,
        priceTotal: props.priceTotal,
        isSelected: props.isSelected,
        isLoading: props.isLoading
    })) !== null && _b !== void 0 ? _b : (React.createElement(React.Fragment, null,
        props.isExclusive && !props.isSelected && (React.createElement("span", { className: cn(theme.exclusivePlate, props.exclusivePlateClassName) }, t('Exclusive'))),
        React.createElement("div", { className: cn(theme.header, props.headerClassName) },
            React.createElement("div", { className: cn(theme.header__text, props.headerTextClassName) },
                props.isSelected && props.icon && React.createElement("div", { className: theme.icon }, props.icon),
                props.header),
            React.createElement("div", { className: cn(theme.header__price, props.headerPriceClassName) }, props.isSelected ? (props.priceTotal ? (React.createElement(Money, { money: props.priceTotal })) : null) : props.priceFrom ? (React.createElement(React.Fragment, null,
                !props.hideFrom && ((_c = props.priceFrom) === null || _c === void 0 ? void 0 : _c.amount) !== 0 && t('from'),
                ((_d = props.priceFrom) === null || _d === void 0 ? void 0 : _d.amount) === 0 ? (props.showFromZeroIfZeroPrice ? (React.createElement("span", null,
                    t('from'),
                    React.createElement(Money, { money: props.priceFrom }))) : (React.createElement(React.Fragment, null,
                    "\u00A0",
                    t('SeatMap:Free')))) : (React.createElement(Money, { money: props.priceFrom })))) : null)))),
        React.createElement("div", { className: theme.main },
            React.createElement("div", { className: cn(theme.content, props.descriptionClassName) }, props.isSelected ? (props.children) : (React.createElement("div", { dangerouslySetInnerHTML: { __html: props.description } }))), (_f = (_e = props.renderControls) === null || _e === void 0 ? void 0 : _e.call(props, {
            buttons: props.buttons,
            priceFrom: props.priceFrom,
            priceTotal: props.priceTotal,
            addButtonText: props.addButtonText,
            showAddButton: props.showAddButton,
            isSelected: props.isSelected,
            isLoading: props.isLoading,
            addClick: props.addClick
        })) !== null && _f !== void 0 ? _f : (React.createElement("div", { className: cn(theme.buttons, props.buttonsClassName) },
            props.isSelected && ((_g = props.buttons) === null || _g === void 0 ? void 0 : _g.length) > 0 && (React.createElement(React.Fragment, null, props.buttons.map(function (button) {
                var _a;
                return (React.createElement(BaseButton, { key: button.text, className: button.className, isLoading: button.isLoading, onClick: function () { return button.action(); }, variant: (_a = button.variant) !== null && _a !== void 0 ? _a : 'text' }, button.text));
            }))),
            !props.isSelected && props.showAddButton !== false && (React.createElement(BaseButton, { isLoading: props.isLoading, variant: "optionAction", className: theme.add, onClick: props.addClick }, props.addButtonText ? t(props.addButtonText) : t('Add')))))),
        props.endAdornment && props.endAdornment));
};
export default CheckoutAdditionalService;
