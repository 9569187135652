import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../theme';
import { useConfig } from '../../../../context';
import { useToggleable } from '../../../../hooks';
import { useIsMobile } from '../../../../utils';
import { getSegmentsForServices } from '../../../store/order/selectors';
import { getSelectedExtraSeats } from '../../../store/passengers/selectors';
import { getIsAllExtraSeatsSelected, getIsAllSeatsSelected } from '../../../utils';
import DoubleSeatsModal from '../../../../DoubleSeatsModal';
import SuggestionModalContent from '../../../../SuggestionModalContent/SuggestionModalContent';
import Modal from '../../../../Modal';
import { SeatIcon } from './icons';
var BookingSeatsWrapper = function (_a) {
    var children = _a.children, passengers = _a.passengers, goToNextStep = _a.goToNextStep;
    var t = useTranslation('SeatMap').t;
    var theme = useTheme('Checkout').BookingSeatsWrapper;
    var showRecommendedSeats = useConfig().global.seatMap.showRecommendedSeats;
    var segments = useSelector(getSegmentsForServices);
    var selectedExtraSeatsServices = useSelector(getSelectedExtraSeats);
    var isMobile = useIsMobile();
    var _b = useToggleable(false), showSeatsModal = _b.isOpen, openSeatsModal = _b.open, closeSeatsModal = _b.close;
    var _c = useToggleable(false), showDoubleSeatsModal = _c.isOpen, openDoubleSeatsModal = _c.open, closeDoubleSeatsModal = _c.close;
    var goToNextStepHandler = function () {
        if (showRecommendedSeats && !getIsAllSeatsSelected(passengers, segments)) {
            openSeatsModal();
        }
        else if (!getIsAllExtraSeatsSelected(selectedExtraSeatsServices, passengers, segments)) {
            openDoubleSeatsModal();
        }
        else {
            goToNextStep();
        }
    };
    return (React.createElement(React.Fragment, null,
        children({ goToNext: goToNextStepHandler }),
        React.createElement(DoubleSeatsModal, { classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, open: showDoubleSeatsModal, onClose: closeDoubleSeatsModal, slideUp: isMobile }),
        React.createElement(Modal, { maxWidth: "xs", classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, open: showSeatsModal, onClose: closeSeatsModal, slideUp: isMobile },
            React.createElement(SuggestionModalContent, { icon: SeatIcon, agreeButtonText: t('Select seat'), disagreeButtonText: t('Move on'), text: t("If you don't select a seat, we'll automatically allocate your seats when you check-in."), onAgree: closeSeatsModal, onDisagree: goToNextStep }))));
};
export default BookingSeatsWrapper;
